import React from 'react';
import BrainScene from './components/three/BrainScene';
import './index.scss';

const App: React.FC = () => {
  return (
    <div className="App">
      <h1 className="title">headquarters</h1>
      <p className="subtitle">making the best-in-class concussion care accessible to all</p>
      <BrainScene />
      <p className="paragraph">coming soon</p>
    </div>
  );
};

export default App; 