import React, { useEffect, useRef, useMemo } from 'react';
import * as THREE from 'three';
import { useThree } from '../../hooks/useThree';
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
import { gsap } from 'gsap';
import vertexShader from '@shaders/brain.vertex.glsl';
import fragmentShader from '@shaders/brain.fragment.glsl';
import { InstancedUniformsMesh } from 'three-instanced-uniforms-mesh';

const isMobile = () => {
  return window.innerWidth <= 768;
};

// Camera movement constants
const CAMERA_MOVE_RANGE = 0.4;  // Controls how far the camera moves with mouse (smaller = more subtle)
const CAMERA_LERP_SPEED = 0.01; // Controls how smoothly the camera follows mouse (smaller = smoother)

// Auto-rotation constants
const AUTO_ROTATION_SPEED = 0.001; // Controls speed of auto-rotation (smaller = slower)
const AUTO_ROTATION_DELAY = 1000;   // Delay in ms before auto-rotation resumes after mouse movement

// Add these constants
const INSTANCE_SIZE = 0.004;  // Size of each instance
const HOVER_DURATION = 0.25;  // Duration for hover animations
const SCATTER_RANGE = 20; // How far the particles scatter
const ASSEMBLY_DURATION = 4.0; // Duration of assembly animation

// Touch control constants
const TOUCH_ROTATION_SPEED = 0.006; // Speed of touch rotation
const PINCH_ZOOM_SPEED = 0.02; // Speed of pinch zoom
const MIN_ZOOM = 1;
const MAX_ZOOM = 8;
const TOUCH_LERP_SPEED = 0.05; // Speed of zoom interpolation

const BrainScene: React.FC = () => {
  const mountRef = useRef<HTMLDivElement>(null);
  const { initScene, cleanup } = useThree({ backgroundColor: 0x000000 });
  const targetCameraPositionRef = useRef(new THREE.Vector3(0, 0, isMobile() ? 4.5 : 2));
  const lastInteractionTimeRef = useRef<number>(Date.now());
  const intersectsRef = useRef<THREE.Intersection[]>([]);
  const hoverRef = useRef(false);
  const pointRef = useRef(new THREE.Vector3());
  const uniformsRef = useRef({ uHover: 0 });
  const instancedMeshRef = useRef<InstancedUniformsMesh<{
    uPointer: THREE.Vector3;
    uColor: THREE.Color;
    uRotation: number;
    uSize: number;
    uHover: number;
  }> | null>(null);
  const brainRef = useRef<THREE.Mesh | null>(null);
  const touchStartRef = useRef({ x: 0, y: 0 });
  const previousTouchesRef = useRef<Touch[]>([]);
  const targetRotationRef = useRef({ x: 0, y: 0 });
  const currentRotationRef = useRef({ x: 0, y: 0 });
  const currentZoomRef = useRef(isMobile() ? 4.5 : 2);
  const targetZoomRef = useRef(isMobile() ? 4.5 : 2);

  // Define colors
  const colors = useMemo(() => [
    new THREE.Color(0x963cbd),
    new THREE.Color(0xff6f61),
    new THREE.Color(0xc5299b),
    new THREE.Color(0xfeae51)
  ], []);

  useEffect(() => {
    if (!mountRef.current) return;

    const { scene, camera, renderer, autoRotationGroup } = initScene(mountRef.current);
    const raycaster = new THREE.Raycaster();
    
    // Add some light
    const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
    scene.add(ambientLight);
    
    const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
    directionalLight.position.set(0, 1, 2);
    scene.add(directionalLight);

    // Modified mouse move handler
    const onMouseMove = (event: MouseEvent) => {
      lastInteractionTimeRef.current = Date.now();
      
      const rect = mountRef.current!.getBoundingClientRect();
      const x = ((event.clientX - rect.left) / mountRef.current!.clientWidth) * 2 - 1;
      const y = -((event.clientY - rect.top) / mountRef.current!.clientHeight) * 2 + 1;

      // Update camera position with GSAP
      gsap.to(camera.position, {
        x: x * CAMERA_MOVE_RANGE,
        y: -y * CAMERA_MOVE_RANGE,
        duration: 0.5
      });

      // Handle raycasting and hover effects
      const mousePosition = new THREE.Vector2(x, y);
      raycaster.setFromCamera(mousePosition, camera);
      
      if (brainRef.current) {
        intersectsRef.current = raycaster.intersectObject(brainRef.current);
        handleHoverState(intersectsRef.current);
      }
    };

    // Add this function to handle hover states
    const handleHoverState = (intersects: THREE.Intersection[]) => {
      if (intersects.length === 0) {
        if (hoverRef.current) {
          hoverRef.current = false;
          animateHoverUniform(0);
        }
      } else {
        if (!hoverRef.current) {
          hoverRef.current = true;
          animateHoverUniform(1);
        }

        gsap.to(pointRef.current, {
          x: intersects[0]?.point.x || 0,
          y: intersects[0]?.point.y || 0,
          z: intersects[0]?.point.z || 0,
          duration: 0.3,
          onUpdate: updatePointerUniforms
        });
      }
    };

    // Add these utility functions
    const animateHoverUniform = (value: number) => {
      gsap.to(uniformsRef.current, {
        uHover: value,
        duration: HOVER_DURATION,
        onUpdate: updateHoverUniforms
      });
    };

    const updatePointerUniforms = () => {
      if (instancedMeshRef.current) {
        for (let i = 0; i < instancedMeshRef.current.count; i++) {
          instancedMeshRef.current.setUniformAt('uPointer', i, pointRef.current);
        }
      }
    };

    const updateHoverUniforms = () => {
      if (instancedMeshRef.current) {
        for (let i = 0; i < instancedMeshRef.current.count; i++) {
          instancedMeshRef.current.setUniformAt('uHover', i, uniformsRef.current.uHover);
        }
      }
    };

    // Load brain model with animation
    const loader = new GLTFLoader();
    loader.load(
      '/brain.glb',
      (gltf) => {
        console.log('Brain model loaded successfully');
        const brainMesh = gltf.scene.children[0] as THREE.Mesh;
        brainRef.current = brainMesh;
        
        // Create instanced mesh material with shaders
        const geometry = new THREE.BoxGeometry(INSTANCE_SIZE, INSTANCE_SIZE, INSTANCE_SIZE);
        const material = new THREE.ShaderMaterial({
          vertexShader,
          fragmentShader,
          wireframe: true,
          uniforms: {
            uPointer: { value: new THREE.Vector3() },
            uColor: { value: new THREE.Color() },
            uRotation: { value: 0 },
            uSize: { value: 0 },
            uHover: { value: uniformsRef.current.uHover }
          }
        });

        // Create instanced mesh
        const instancedMesh = new InstancedUniformsMesh<{
          uPointer: THREE.Vector3;
          uColor: THREE.Color;
          uRotation: number;
          uSize: number;
          uHover: number;
        }>(
          geometry,
          material,
          brainMesh.geometry.attributes.position.count
        );
        instancedMeshRef.current = instancedMesh;

        // Setup instances
        const positions = brainMesh.geometry.attributes.position.array;
        const originalPositions: THREE.Vector3[] = [];
        const scatteredPositions: THREE.Vector3[] = [];

        // Store original and create scattered positions
        for (let i = 0; i < positions.length; i += 3) {
          const originalPos = new THREE.Vector3(
            positions[i],
            positions[i + 1],
            positions[i + 2]
          );
          originalPositions.push(originalPos);
          
          // Create a scattered position
          const scatteredPos = originalPos.clone().add(
            new THREE.Vector3(
              (Math.random() - 0.5) * SCATTER_RANGE,
              (Math.random() - 0.5) * SCATTER_RANGE,
              (Math.random() - 0.5) * SCATTER_RANGE
            )
          );
          scatteredPositions.push(scatteredPos);
        }

        // Set initial scattered positions
        const dummy = new THREE.Object3D();
        for (let i = 0; i < originalPositions.length; i++) {
          dummy.position.copy(scatteredPositions[i]);
          dummy.updateMatrix();
          
          instancedMesh.setMatrixAt(i, dummy.matrix);
          instancedMesh.setUniformAt('uRotation', i, THREE.MathUtils.randFloat(-1, 1));
          instancedMesh.setUniformAt('uSize', i, THREE.MathUtils.randFloat(0.3, 3));
          
          const colorIndex = THREE.MathUtils.randInt(0, colors.length - 1);
          instancedMesh.setUniformAt('uColor', i, colors[colorIndex]);
        }

        instancedMesh.instanceMatrix.needsUpdate = true;
        autoRotationGroup.add(instancedMesh);

        // Animate assembly
        for (let i = 0; i < originalPositions.length; i++) {
          gsap.to(scatteredPositions[i], {
            x: originalPositions[i].x,
            y: originalPositions[i].y,
            z: originalPositions[i].z,
            duration: ASSEMBLY_DURATION,
            ease: "power2.out",
            delay: Math.random() * 0.5, // Stagger the animation
            onUpdate: () => {
              dummy.position.copy(scatteredPositions[i]);
              dummy.updateMatrix();
              instancedMesh.setMatrixAt(i, dummy.matrix);
              instancedMesh.instanceMatrix.needsUpdate = true;
            }
          });
        }

        // Animate in
        gsap.from(instancedMesh.scale, {
          x: 0,
          y: 0,
          z: 0,
          duration: 1.5,
          ease: "power2.out"
        });

        // Add mousemove listener after model is loaded
        window.addEventListener('mousemove', onMouseMove);

        // Add this after the brain assembly animations
        const startTextSequence = () => {
          // Hide all text elements initially
          gsap.set(['.title', '.subtitle', '.paragraph'], { 
            opacity: 0 
          });

          // Create timeline for text animations
          const tl = gsap.timeline({
            delay: ASSEMBLY_DURATION + 0.5 // Start after brain assembly plus a small pause
          });

          // Animate title
          tl.to('.title', {
            opacity: 1,
            duration: 0.8,
            ease: 'power2.out'
          });

          // Animate subtitle
          tl.to('.subtitle', {
            opacity: 1,
            duration: 0.8,
            ease: 'power2.out'
          }, '-=0.4');

          // Animate paragraph
          tl.to('.paragraph', {
            opacity: 1,
            duration: 0.8,
            ease: 'power2.out'
          }, '-=0.4');
        };

        // Call the sequence
        startTextSequence();
      },
      (progress) => {
        console.log('Loading progress:', (progress.loaded / progress.total * 100) + '%');
      },
      (error) => {
        console.error('Error loading brain model:', error);
      }
    );

    // Updated animation loop
    const animate = () => {
      requestAnimationFrame(animate);
      
      // Smooth camera movement
      camera.position.lerp(targetCameraPositionRef.current, CAMERA_LERP_SPEED);
      
      // Smooth zoom interpolation
      currentZoomRef.current += (targetZoomRef.current - currentZoomRef.current) * TOUCH_LERP_SPEED;
      camera.position.z = currentZoomRef.current;
      
      // Smooth rotation interpolation
      currentRotationRef.current.x += (targetRotationRef.current.x - currentRotationRef.current.x) * TOUCH_LERP_SPEED;
      currentRotationRef.current.y += (targetRotationRef.current.y - currentRotationRef.current.y) * TOUCH_LERP_SPEED;
      
      autoRotationGroup.rotation.x = currentRotationRef.current.x;
      autoRotationGroup.rotation.y = currentRotationRef.current.y;

      camera.lookAt(0, 0, 0);
      renderer.render(scene, camera);
    };
    animate();

    // Handle window resize
    const handleResize = () => {
      if (!mountRef.current) return;
      const width = mountRef.current.clientWidth;
      const height = mountRef.current.clientHeight;
      camera.aspect = width / height;
      
      // Update camera position based on screen size
      const newZoom = isMobile() ? 4.5 : 2;
      targetCameraPositionRef.current.z = newZoom;
      currentZoomRef.current = newZoom;
      targetZoomRef.current = newZoom;
      camera.position.z = newZoom;
      
      camera.updateProjectionMatrix();
      renderer.setSize(width, height);
    };
    window.addEventListener('resize', handleResize);

    const onTouchStart = (event: TouchEvent) => {
      event.preventDefault();
      touchStartRef.current = { x: event.touches[0].clientX, y: event.touches[0].clientY };
      previousTouchesRef.current = Array.from(event.touches);
    };
    
    const onTouchMove = (event: TouchEvent) => {
      event.preventDefault();
      lastInteractionTimeRef.current = Date.now();
    
      // Handle pinch zoom if there are two touches
      if (event.touches.length === 2 && previousTouchesRef.current.length === 2) {
        const currentDistance = Math.hypot(
          event.touches[0].clientX - event.touches[1].clientX,
          event.touches[0].clientY - event.touches[1].clientY
        );
        
        const previousDistance = Math.hypot(
          previousTouchesRef.current[0].clientX - previousTouchesRef.current[1].clientX,
          previousTouchesRef.current[0].clientY - previousTouchesRef.current[1].clientY
        );

        const delta = (currentDistance - previousDistance) * PINCH_ZOOM_SPEED;
        targetZoomRef.current = Math.max(MIN_ZOOM, Math.min(MAX_ZOOM, targetZoomRef.current - delta));
      }
      // Handle single touch rotation
      else if (event.touches.length === 1) {
        const touch = event.touches[0];
        const deltaX = touch.clientX - touchStartRef.current.x;
        const deltaY = touch.clientY - touchStartRef.current.y;
        
        targetRotationRef.current.y += deltaX * TOUCH_ROTATION_SPEED;
        targetRotationRef.current.x += deltaY * TOUCH_ROTATION_SPEED;
        
        touchStartRef.current = { x: touch.clientX, y: touch.clientY };
      }

      previousTouchesRef.current = Array.from(event.touches);
    };

    // Add touch event listeners
    mountRef.current.addEventListener('touchstart', onTouchStart, { passive: false });
    mountRef.current.addEventListener('touchmove', onTouchMove, { passive: false });

    return () => {
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('resize', handleResize);
      mountRef.current?.removeChild(renderer.domElement);
      cleanup();
      mountRef.current?.removeEventListener('touchstart', onTouchStart);
      mountRef.current?.removeEventListener('touchmove', onTouchMove);
    };
  }, []);

  return (
    <div 
      ref={mountRef} 
      style={{ 
        width: '100%', 
        height: '100vh',
        background: '#000'
      }}
    />
  );
};

export default BrainScene;