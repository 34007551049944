import { useEffect, useRef } from 'react';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { InstancedUniformsMesh } from 'three-instanced-uniforms-mesh';

interface UseThreeProps {
  backgroundColor?: number;
  onModelLoaded?: (geometry: THREE.BufferGeometry) => void;
}

export const useThree = ({ backgroundColor = 0x000000, onModelLoaded }: UseThreeProps = {}) => {
  const sceneRef = useRef<THREE.Scene | null>(null);
  const cameraRef = useRef<THREE.PerspectiveCamera | null>(null);
  const rendererRef = useRef<THREE.WebGLRenderer | null>(null);
  const autoRotationGroupRef = useRef<THREE.Group | null>(null);

  const initScene = (container: HTMLDivElement) => {
    // Scene setup
    const scene = new THREE.Scene();
    scene.background = new THREE.Color(backgroundColor);
    sceneRef.current = scene;

    // Camera setup with adjusted values for brain visualization
    const camera = new THREE.PerspectiveCamera(
      60,
      container.clientWidth / container.clientHeight,
      0.1,
      1000
    );
    camera.position.z = 0.4; // Closer camera for brain visualization
    cameraRef.current = camera;

    // Renderer setup with transparency
    const renderer = new THREE.WebGLRenderer({ 
      antialias: true,
      alpha: true 
    });
    renderer.setSize(container.clientWidth, container.clientHeight);
    renderer.setPixelRatio(Math.min(1.5, window.devicePixelRatio));
    renderer.physicallyCorrectLights = true;
    container.appendChild(renderer.domElement);
    rendererRef.current = renderer;

    // Add auto-rotation group
    const autoRotationGroup = new THREE.Group();
    scene.add(autoRotationGroup);
    autoRotationGroupRef.current = autoRotationGroup;

    return { 
      scene, 
      camera, 
      renderer,
      autoRotationGroup 
    };
  };

  const cleanup = () => {
    if (rendererRef.current) {
      rendererRef.current.dispose();
    }
  };

  return {
    initScene,
    cleanup,
    scene: sceneRef.current,
    camera: cameraRef.current,
    renderer: rendererRef.current,
    autoRotationGroup: autoRotationGroupRef.current
  };
};
